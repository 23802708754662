import { defineStore } from 'pinia'

type FeatureFlagCollection = models.stores.siteConfig.FeatureFlagCollection

interface State {
  featureFlags: models.stores.siteConfig.FeatureFlagCollection
}

export const useFeatureFlagStore = defineStore('featureFlag', {
  state: (): State => ({
    featureFlags: {} as FeatureFlagCollection
  }),
  actions: {
    async fetch() {
      if (this.featureFlags && Object.keys(this.featureFlags).length)
        return this.featureFlags

      const siteConfigStore = useSiteConfigStore()

      // Perform fetch on site config.
      await siteConfigStore.fetch()

      // Extract parsed feature flags from site config.
      const configFeatureFlags = siteConfigStore.config.featureFlags
      if (configFeatureFlags) {
        this.featureFlags = configFeatureFlags
      }
      return this.featureFlags
    }
  }
})
